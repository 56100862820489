<template>
  <div>
    <v-simple-table
      class="table-padding-2"
      :fixed-header="!!idWarehouse"
      :height="!idWarehouse ? 'calc(100vh - 145px)' : ''"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th
              v-if="!idWarehouse"
              role="columnheader"
              class="text-center"
              style="width: 120px"
            >
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                @onFilter="onFilterChange"
                sort-name="warehouse_code"
                :sorting="filters.sort_by"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center" style="width: 235px">
              <SelectFilter
                :options="licenseTypes"
                :label="$t('labels.license')"
                :placeholder="$t('labels.license')"
                name="id_license_type"
                @onFilter="onFilterChange"
                sort-name="id_license_type"
                :sorting="filters.sort_by"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center" style="width: 150px">
              <SelectFilter
                :options="classifyOptions"
                :label="$t('labels.classify')"
                :placeholder="$t('labels.classify')"
                name="type"
                @onFilter="onFilterChange"
                sort-name="classify"
                :sorting="filters.sort_by"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center" style="width: 110px">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.time_limit')"
                :placeholder="$t('labels.time_limit')"
                name="time_limit"
                @onFilter="onFilterChange"
                sort-name="time_limit"
                :sorting="filters.sort_by"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center" style="width: 120px">
              <DateRangeFilter
                :label="$t('labels.started_date')"
                :placeholder="$t('labels.started_date')"
                name="started_date"
                @onFilter="onFilterChange"
                sort-name="started_date"
                :sorting="filters.sort_by"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center" style="width: 120px">
              <DateRangeFilter
                :label="$t('labels.expired_date')"
                :placeholder="$t('labels.expired_date')"
                name="expired_date"
                @onFilter="onFilterChange"
                sort-name="expired_date"
                :sorting="filters.sort_by"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.note')"
                :placeholder="$t('labels.note')"
                name="note"
                @onFilter="onFilterChange"
                sort-name="note"
                :sorting="filters.sort_by"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center" style="width: 110px">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="status"
                @onFilter="onFilterChange"
                sort-name="status"
                :sorting="filters.sort_by"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <v-btn
                color="success"
                small
                @click="addConfig"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id" class="text-center">
            <td>
              <span v-if="item.id">{{ item.warehouse_code }}</span>
              <select-warehouse-model
                v-else
                :disabled="!item.editing"
                v-model="item.id_warehouse"
                :placeholder="$t('labels.license')"
              ></select-warehouse-model>
            </td>
            <td>
              <v-autocomplete
                :disabled="!item.editing"
                class="c-input-xs"
                v-model="item.id_license_type"
                :items="licenseTypes"
                :placeholder="$t('labels.license')"
                dense
                outlined
                clearable
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              {{ getClassify(item) }}
            </td>
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.time_limit"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td>
              <date-picker-model
                v-if="item.time_limit"
                v-model="item.started_date"
                custom-class="c-input-xs"
                :disabled="!item.editing"
              ></date-picker-model>
            </td>
            <td>
              <date-picker-model
                v-if="item.time_limit"
                v-model="item.expired_date"
                custom-class="c-input-xs"
                :disabled="!item.editing"
              ></date-picker-model>
            </td>
            <td>
              <v-text-field
                :disabled="!item.editing"
                class="c-input-xs"
                v-model="item.note"
                :placeholder="$t('labels.note')"
                dense
                outlined
                clearable
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              {{ item.status ? "Yes" : "No" }}
            </td>
            <td class="py-1">
              <template v-if="!item.editing">
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  class="mb-1"
                  x-small
                  color="success"
                  @click="updateConfig(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        v-if="totalPage > 1"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  name: "LicenseTable",
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
  },
  props: {
    idWarehouse: {
      type: [Number, String],
      default: () => null,
    },
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    licenseTypes: [],
    yesNoOptions: [...YES_NO_OPTIONS],
    classifyOptions: [
      {
        text: "Cần thiết",
        value: 1,
      },
      {
        text: "Tùy chọn",
        value: 0,
      },
    ],
    items: [],
    filters: {},
    isLoading: false,
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.filters.id_warehouse = this.idWarehouse;
    this.getTypes();
    this.getList();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        id_license_type: false,
        id_warehouse: this.idWarehouse,
        time_limit: false,
        expired_date: null,
        note: null,
        editing: true,
      });
    },
    async updateConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/warehouse/v1/save-warehouse-license", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/warehouse/v1/get-warehouse-license", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows].map((item) => ({
            ...item,
            editing: false,
          }));
        });
    }, 500),
    getTypes: debounce(function () {
      httpClient.post("/warehouse/v1/get-license-type").then(({ data }) => {
        this.licenseTypes = [...data].map((item) => ({
          text: item.name,
          value: item.id,
          type: item.type,
        }));
      });
    }, 500),
    getClassify(item) {
      if (
        !item.id_license_type ||
        !this.licenseTypes ||
        this.licenseTypes.length === 0
      ) {
        return null;
      }
      const licenseType = [...this.licenseTypes].find(
        (t) => t.value === item.id_license_type
      );
      return licenseType.type === 1 ? "Cần thiết" : "Tùy chọn";
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>

<style scoped></style>
